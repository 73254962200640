<template>
  <Loader v-if="loading" />
  <div v-else id="submissionPage">
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="container flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-top-users.svg" class="h-4 w-4" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base">
              {{ submission?.form?.name }}
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-between w-full">
          <button
            @click="$router.back()"
            class="
              add-user
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              text-white text-xs
              uppercase
            "
          >
            {{ $t("back") }}
          </button>
          <button @click="print">
            <img src="/icon-print.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
    <section
      class="submission-detail lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6"
    >
      <div
        class="
          bg-white
          mb-1
          mt-0
          mx-auto
          p-4
          rounded-lg
          shadow-2xl shadow-gray-100
          lg:px-4
        "
      >
        <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
          <span>* {{ error }}</span>
        </div>
        <div v-else class="flex flex-col items-start" id="printSubmission">
          <div class="flex flex-row mb-5 gap-x-3">
            <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
              <p class="text-sm">{{ $t("submission-number") }}:</p>
            </div>
            <div class="flex w-auto sm:w-64">
              <p class="text-sm">{{ submission?.submissionNumber }}</p>
            </div>
          </div>

          <div class="flex flex-row mb-5 gap-x-3">
            <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
              <p class="text-sm">{{ $t("submitter") }}:</p>
            </div>
            <div class="flex w-auto sm:w-64">
              <p class="text-sm">{{ submission?.submittedBy?.name }}</p>
            </div>
          </div>

          <div class="flex flex-row mb-5 gap-x-3">
            <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
              <p class="text-sm">{{ $t("added") }}:</p>
            </div>
            <div class="flex w-auto sm:w-64">
              <p class="text-sm">{{ formatDate(submission?.createdAt) }}</p>
            </div>
          </div>

          <div class="flex flex-row mb-5 gap-x-3">
            <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
              <p class="text-sm">{{ $t("group") }}:</p>
            </div>
            <div class="flex w-auto sm:w-64">
              <p class="text-sm">{{ submission?.form?.group?.name || "" }}</p>
            </div>
          </div>

          <div class="flex flex-row mb-5 gap-x-3">
            <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
              <p class="text-sm">{{ $t("sub-group") }}:</p>
            </div>
            <div class="flex w-auto sm:w-64">
              <p class="text-sm">
                {{ submission?.form?.subgroup?.name || "" }}
              </p>
            </div>
          </div>

          <div class="flex flex-row mb-5 gap-x-3">
            <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
              <p class="text-sm">{{ $t("sub-sub-group") }}:</p>
            </div>
            <div class="flex w-auto sm:w-64">
              <p class="text-sm">
                {{ submission?.form?.sub_subgroup?.name || "" }}
              </p>
            </div>
          </div>

          <div class="border-gray-100 border-t mb-6 mt-2 w-full"></div>
          <div v-for="(value, name) in submission?.data" :key="name">
            <div v-if="typeof value === 'object' && Object.keys(value).length">
              <div class="flex flex-row mb-5 gap-x-3">
                <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
                  <p class="text-sm">{{ name }}:</p>
                </div>

                <div class="flex w-auto sm:w-64">
                  <p class="text-sm">{{ getNestedVal(value) }}</p>
                </div>
              </div>
            </div>

            <div v-else class="flex flex-row mb-5 gap-x-3">
              <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
                <p class="text-sm">{{ name }} :</p>
              </div>

              <div class="flex w-auto sm:w-64">
                <p class="text-sm">{{ value }}</p>
              </div>
            </div>
          </div>

          <div v-for="(val, key) in submission?.uploads" :key="key">
            <div v-if="Array.isArray(val)" class="flex flex-row mb-5 gap-x-3">
              <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
                <p class="text-sm">{{ key }}:</p>
              </div>

              <div class="flex items-center gap-x-2 flex-wrap">
                <a
                  @click.prevent="
                    downloadItem({
                      url: getUrl(file.url),
                      label: file.name,
                      Key: file.Key,
                    })
                  "
                  :href="getUrl(file.url)"
                  class="
                    flex
                    items-center
                    gap-2
                    mb-2
                    uploaded
                    text-gray-800 text-sm
                    py-1
                    px-3
                    rounded
                    cursor-pointer
                  "
                  v-for="(file, idx) in val"
                  :key="idx"
                >
                  <span>{{ getShortFileName(file.name, file.Key) }}</span>
                  <img src="/download-icon-yellow.svg" alt="download" />
                </a>
              </div>
            </div>
            <div v-else class="flex flex-row mb-5 gap-x-3">
              <div class="flex font-thin text-WADARKBLUE-500 w-auto sm:w-48">
                <p class="text-sm">{{ key }}:</p>
              </div>

              <div class="flex w-auto sm:w-64">
                <a
                  @click.prevent="
                    downloadItem({
                      url: getUrl(val.url),
                      label: val.name,
                      Key: file.Key,
                    })
                  "
                  :href="getUrl(val.url)"
                  class="
                    flex
                    items-center
                    gap-2
                    text-sm
                    py-1
                    px-3
                    mb-2
                    uploaded
                    text-gray-700
                    rounded
                    cursor-pointer
                  "
                >
                  <span>{{ getShortFileName(val.name, val.Key) }}</span>
                  <img src="/download-icon-yellow.svg" alt="download" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { mapActions, mapGetters } from "vuex";
import httpClient from "../services/httpClient";

export default {
  components: { Loader },

  computed: {
    ...mapGetters("submissions", ["loading", "error"]),
  },

  async created() {
    this.submission = await this.getSubmissionById(this.id);
  },

  data() {
    return {
      submission: null,
    };
  },

  methods: {
    ...mapActions("submissions", ["getSubmissionById"]),

    getNestedVal(value) {
      let allData = [];

      for (const [key, val] of Object.entries(value)) {
        if (val) allData.push(key);
      }

      return allData.join(", ");
    },

    getShortFileName(name, Key) {
      const ext = Key.substr(Key.lastIndexOf(".") + 1);
      const shortName = name.length > 8 ? `${name.substr(0, 8)}...` : name;
      return name.length > 8 ? shortName + ext : name;
    },

    async downloadItem({ url, label, Key }) {
      const response = await httpClient.get(url, {
        responseType: "blob",
      });
      const ext = Key.substr(Key.lastIndexOf(".") + 1);
      const blob = new Blob([response.data], { type: "*" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${label}.${ext}`;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    getUrl(link) {
      return `${process.env.VUE_APP_API_URL}${link}`;
    },

    formatDate(date) {
      if (date) {
        const d = new Date(date);

        const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
          d
        );
        const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

        return `${day}.${mo}.${ye}`;
      }
    },

    async print() {
      let container = document.getElementById("app").cloneNode(true);
      container.querySelector("#header-menu").innerHTML = "";

      let contents = container.innerHTML;

      let frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      let frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;

      frameDoc.document.open();
      frameDoc.document.write(
        `<html lang="en"><head><title>${this.submission.form.name}</title>`
      );
      frameDoc.document.write(
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css"/>'
      );
      frameDoc.document.write('<link rel="stylesheet" href="/tailwind.css"/>');
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();

      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();

        document.body.removeChild(frame1);
      }, 500);
    },
  },
  props: ["id"],
  watch: {
    async id(newVal) {
      this.submission = await this.getSubmissionById(newVal);
    },
  },
};
</script>

<style>
.uploaded {
  border: 1px solid #d97706bb;
}
</style>